<template>
  <div class="loginView">
    <img src="/images/appMainView/logo.png" class="loginView_logo">
    <van-popup v-model="showProvison" round :close-on-click-overlay='false'>
      <provision @cancle='handleCancle' @confirm='handleConfirm'/>
    </van-popup>
  </div>
</template>

<script>
// import {oauthLogin, wechatLogin} from "../../api/login";
import { wechatLogin} from "../../api/login";
import {loginCount, addLoginCount} from "../../api/suser";
import provision from './components/provision'

export default {
  name: "wxchatcode",
  components: {
    provision
  },
  data() {
    return {
      timer: null,
      showProvison: false
    }
  },
  created() {
    //this.$dialog.alert({message:"社区正在维护中，感谢您的访问！"})
    this.init()
  },
  methods: {
    init: function () {
      let oauthToken = this.$route.query.code;
      // 测试环境还是走测试登录
      if(window.location.href.indexOf("zeekrlife") === -1){
        // oauthLogin({oauthToken: "2000000001"}).then(res=>{
        //   this.$store.commit('user/SET_USERINFO', res);
        //   this.getLoginCount()
        // })
         this.timer = setTimeout(() => {
          this.$router.replace({name: 'error', query: {oauthToken}})
        }, 10000)
        wechatLogin({code: oauthToken,mobile: true}).then(res => {
          this.$store.commit('user/SET_USERINFO', res);
          this.getLoginCount()
        })
        return;
      }
      // 正式环境走正常登录
      if (oauthToken) {
        this.timer = setTimeout(() => {
          this.$router.replace({name: 'error', query: {oauthToken}})
        }, 10000)
        wechatLogin({code: oauthToken,mobile: true}).then(res => {
          this.$store.commit('user/SET_USERINFO', res);
          this.getLoginCount()
        })
      }
    },
    async getLoginCount() {
      const res = await loginCount()
      if(res === 0) {
        this.showProvison = true
        clearTimeout(this.timer)
      } else {
        await addLoginCount()
        this.$router.replace("/")
      }
    },
    //法务条款取消事件
    handleCancle() {
      window.close()
    },
    //法务条款确认事件
    async handleConfirm() {
      // await addLoginCount()
      this.$router.replace("/")
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style scoped lang="scss">
.loginView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &_logo {
    width: 100px;
  }
  .van-popup {
    width: 80%;
    padding: 20px;
    height: 80%;
  }
}
</style>
